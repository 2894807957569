<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Perfil</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.status"
                        label="Ativar"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row style="height: 100px">
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Nome</label>
                    <v-text-field
                        v-model="perfilRequest.nome" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="3"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Margem Mínima - Prod. Nacionais</label>
                    <v-text-field
                        v-model="perfilRequest.margemMinimaProdutoNacional" 
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="3"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>Margem Mínima - Prod. Importados</label>
                    <v-text-field
                        v-model="perfilRequest.margemMinimaProdutoImportado" 
                        suffix=" %"
                        v-formatMoney="percFormat"
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Permissões'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.criacaoPedidos"
                        label="Criação de Pedidos?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.editarValidarPedidoOutroUsuario"
                        label="Editar / Validar Pedido de Outro Usuario?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.acompanharPedido"
                        label="Acompanha Pedidos?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.acompanharPedidoOutroUsuario"
                        label="Acompanha Pedidos de Outros Usuários?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.visualizarMargemEfetiva"
                        label="Visualiza Margem Efetiva?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.aprovarPedido"
                        label="Aprova Pedido?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.visualizarCustoProduto"
                        label="Visualiza Custo Produto?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.visualizarICP"
                        label="Visualiza ICP?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.dataEntregaAnoSuperior"
                        label="Permite programar data de entrega para o ano superior ao atual?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.cliente"
                        label="Perfil de cliente?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.cancelarPedido"
                        label="Cancelar Pedidos?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <v-switch 
                        v-model="perfilRequest.visualizarFlagAbatimentoCampanhaCredito"
                        label="Visualiza Flag de Campanha de Crédito?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Acesso aos Menus'" />
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in perfilRequest.listPerfilMenu" :key="index" style="height: 80px;" >
                <v-col 
                    cols="10"
                    lg="10"
                    md="10"
                    sm="10"
                >
                    <v-combobox
                        v-model="item.menuSelected"
                        :items="listMenu"
                        item-text="nomeAmigavel"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col cols="2" :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'">
                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="addNewItem(item)" 
                                color="blue" 
                                small
                                :key="index"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Adicionar Novo Item</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="deleteItem(index)" 
                                color="red" 
                                small
                                :disabled="perfilRequest.listPerfilMenu.length <= 1"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Excluir Item</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="perfilRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons
        },

        data: () => ({

            validForm: true,

            perfilRequest: {
                id: 0,
                nome: "",
                margemMinimaProdutoNacional: 0,
                margemMinimaProdutoImportado: 0,
                criacaoPedidos: 0,
                editarValidarPedidoOutroUsuario: 0,
                acompanharPedido: 0,
                acompanharPedidoOutroUsuario: 0,
                visualizarMargemEfetiva: 0,
                aprovarPedido: 0,
                visualizarCustoProduto: 1,
                visualizarICP: 1,
                dataEntregaAnoSuperior: 0,
                cliente: 0,
                cancelarPedido: 0,
                visualizarFlagAbatimentoCampanhaCredito: 0,
                listPerfilMenu: []
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            listMenu: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            },

            clonar: {
                default: false
            },
        },

        methods: {
            
            async deleteItem(index) {
                this.perfilRequest.listPerfilMenu.splice(index, 1);
                
                let lastIndex = this.perfilRequest.listPerfilMenu.length - 1;

                this.perfilRequest.listPerfilMenu[lastIndex].showAddNewItem = true;
            },

            async addNewItem(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.perfilRequest.listPerfilMenu.push({
                    menuSelected: null,
                    showAddNewItem: true
                })
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("perfilModule/GetById", this.id);

                    if (response.success) {
                        this.perfilRequest = response.result;
                        
                        this.perfilRequest.margemMinimaProdutoNacional = this.perfilRequest.margemMinimaProdutoNacionalFormatted.toString().replaceAll(" %", "");
                        this.perfilRequest.margemMinimaProdutoImportado = this.perfilRequest.margemMinimaProdutoImportadoFormatted.toString().replaceAll(" %", "");

                        if (this.perfilRequest.listPerfilMenu.length == 0) {
                            this.addNewItem();
                        }
                    }

                }
                else {
                    this.perfilRequest.status = 1;
                    this.addNewItem();
                }
            },

            async getLists() {
                
                this.listMenu = await this.$store.dispatch("menuModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/admin/perfilList" });
            },

            async validateProfileMenu() {

                let valid = true;

                if (this.perfilRequest.listPerfilMenu !== null && this.perfilRequest.listPerfilMenu !== undefined) {

                    let listMenu = [...this.perfilRequest.listPerfilMenu];
                    let newListMenu = [];

                    this.perfilRequest.listPerfilMenu.forEach(itemProfileMenu => {
                        
                        if (itemProfileMenu.menuSelected) {
                            
                            let existItem = listMenu.filter(ems => ems.menuSelected.id === itemProfileMenu.menuSelected.id);

                            if (existItem.length > 1 && valid) {
                                this.showToast("warning", "Aviso!", `Menu [${itemProfileMenu.menuSelected.description}] já foi adicionado!`);
                                valid = false;
                            }
                            else {
                                newListMenu.push(itemProfileMenu);
                            }
                        }
                    });

                    if (valid) {
                        this.perfilRequest.listPerfilMenu = newListMenu;
                    }
                }

                return valid;
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {

                    if (await this.validateProfileMenu()) {
                        this.loading = true;
                        this.showLoading();

                        if (this.perfilRequest.criacaoPedidos === null) {
                            this.perfilRequest.criacaoPedidos = 0;
                        }

                        if (this.perfilRequest.editarValidarPedidoOutroUsuario === null) {
                            this.perfilRequest.editarValidarPedidoOutroUsuario = 0;
                        }

                        if (this.perfilRequest.acompanharPedido === null) {
                            this.perfilRequest.acompanharPedido = 0;
                        }

                        if (this.perfilRequest.acompanharPedidoOutroUsuario === null) {
                            this.perfilRequest.acompanharPedidoOutroUsuario = 0;
                        }

                        if (this.perfilRequest.visualizarMargemEfetiva === null) {
                            this.perfilRequest.visualizarMargemEfetiva = 0;
                        }

                        if (this.perfilRequest.aprovarPedido === null) {
                            this.perfilRequest.aprovarPedido = 0;
                        }

                        if (this.perfilRequest.visualizarCustoProduto === null) {
                            this.perfilRequest.visualizarCustoProduto = 0;
                        }

                        if (this.perfilRequest.visualizarICP === null) {
                            this.perfilRequest.visualizarICP = 0;
                        }

                        if (this.perfilRequest.dataEntregaAnoSuperior === null) {
                            this.perfilRequest.dataEntregaAnoSuperior = 0;
                        }

                        if (this.perfilRequest.cliente === null) {
                            this.perfilRequest.cliente = 0;
                        }

                        if (this.perfilRequest.cancelarPedido === null) {
                            this.perfilRequest.cancelarPedido = 0;
                        }

                        if (this.perfilRequest.visualizarFlagAbatimentoCampanhaCredito === null) {
                            this.perfilRequest.visualizarFlagAbatimentoCampanhaCredito = 0;
                        }

                        if (this.perfilRequest.status === null) {
                            this.perfilRequest.status = 0;
                        }

                        this.perfilRequest.id = this.id;
                        
                        if (this.clonar == true) {
                            this.perfilRequest.id = 0;
                        }

                        const result = await this.$store.dispatch("perfilModule/CreateUpdate", this.perfilRequest);

                        if (result.success === true) {
                            this.showToast("success", "Sucesso!", result.message);
                            this.$router.push({ path: "/admin/perfilList" });
                        }
                        else {
                            this.showToast("error", "Aviso!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>